import logo from './logo.svg';
import './App.css';
import WebcamCapture from './web-cam';

function App() {
  
  return (
    <div className="App">
      <h2>ESCANEAR PASAPORTE</h2>
      <WebcamCapture />
    </div>
  );
}

export default App;
