import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import Tesseract from 'tesseract.js';

const WebcamCapture = () => {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [passportInfo, setPassportInfo] = useState(null);

  const videoConstraints = {
    facingMode: { exact: 'environment' } // Utiliza la cámara posterior si está disponible
  };

  const captureAndProcess = async () => {
    const imageSrc = webcamRef.current.getScreenshot();

    // Configura Tesseract.js para reconocer texto en la imagen
    await Tesseract.recognize(
      imageSrc,
      'eng', // Idioma de reconocimiento (puedes ajustarlo según el idioma de tu pasaporte)
      { logger: m => console.log(m) } // Opcional: para ver los logs de reconocimiento
    ).then(({ data: { text } }) => {
      console.log(text)
      setPassportInfo(text);
    });
  };
  return (
    <>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width="100%"
        videoConstraints={videoConstraints}
      />
      <button onClick={captureAndProcess}>Capture Passport</button>
      {passportInfo && (
        <div>
          <h2>Passport Information:</h2>
          <p>{passportInfo}</p>
        </div>
      )}
    </>
  );
};

export default WebcamCapture;
